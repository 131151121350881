@import '../../css/utilities/_mixin.scss';
@import '../../css/utilities/_variables.scss';

.admin {
  margin-top: 40px;
  margin-bottom: 40px;
  @include inner__layout--whole;
  .headtwo {
    text-transform: uppercase;
  }
  .headthree {
    font-size: 1.4em !important;
    background-color: $color-secondary;
    color: $white;
    padding: 5px 10px;
  }
  .header-flex {
    @include flexbox-sb;
  }
  .link {
    line-height: 120%;
  }
  input {
    margin: 5px 0px 15px 10px;
    &[type='radio'] {
      margin-right: 10px;
    }
    &[type='text'] {
      width: 95%;
      font-size: 0.9em;
    }
  }
  textarea {
    margin: 5px 0px 15px 10px;
    width: 95%;
    min-height: 150px;
  }
  select {
    margin: 5px 0px 15px 10px;
    width: 95%;
    @include border-radius(0);
  }
  label {
    min-width: 20%;
    display: block;
    margin-bottom: 5px;
  }
  button {
    background-color: $color-accent;
    color: $white;
    &:hover {
      background-color: $color-accent-light;
    }
    &:active {
      background-color: $color-accent-dark;
    }
  }
  hr {
    margin: 80px 0;
  }
  .bread-crumbs {
    margin: 10px 0;
    a {
      color: $color-primary;
    }
  }
  .ql-editor {
    padding: 20px;
  }
}

.display {
  &__layout {
    border: 2px solid $color-bg;
    margin: 10px 0 60px;
    li {
      padding: 8px 15px;
      @include flexbox(row, nowrap, space-between);
      p {
        padding: 0;
        margin: 0;
        font-size: 1.1em;
      }
      &:nth-child(odd) {
        background-color: $color-bg;
      }
      &::before {
        //background-image: url('../images/search-icon.png');
        background-repeat: no-repeat;
        background-position: center center;
      }
    }
  }
  &__subtitle {
    color: $color-grayDark;
    &--click {
      color: $color-accent;
      margin-left: 15px;
    }
  }
}

.title__wrap {
  @include flexbox-sb;
  @include flex-align(flex-end);
  margin: 15px 0;
  h2 {
    margin-bottom: 0;
  }
}

.edit-link::before {
}

.required::after {
  content: '*';
}

.question {
  display: inline-block;
  font-weight: $font-p-bold;
  width: 30%;
  margin-bottom: 0;
  margin-top: 5px;
  &__wrap {
    margin: 20px 0;
    @include flexbox(row, wrap, flex-start);
  }
  &__option {
    display: inline-block;
    width: 70%;
    &__enclosed {
      input {
        width: 300px !important;
      }
    }
  }
}

.topic__drag-drop {
  &__left {
    @include flexbox(row, nowrap, space-between);
    @include flex-align(center);
  }
  span {
    margin-left: 10px;
  }
  &__numbering {
    width: 40px;
    margin: 0 !important;
  }
  &__type {
    width: 100px;
    color: $color-grayDark;
    &--intro {
      margin-left: 50px !important;
    }
  }
  &__icon {
    width: 20px;
    margin-right: 20px;
  }
}

.section {
  &__list--left {
  }
  &__type {
    width: 100px;
    display: inline-block;
    color: $color-grayDark;
  }
  &__see-all {
    @include center-align-block;
  }
  &__searchbox {
    @include flexbox-sb;
    @include flex-align(center);
    margin: 10px 0;
    border: 1px solid $color-grayLight;
    border-radius: 5px;
    &__btn {
      background-color: transparent !important;
      border: 0;
    }
    input {
      border: 0;
      margin: 0;
    }
  }
  &__filters {
    margin: 2em 0;
    &__title {
      font-weight: 600;
      margin-right: 10px;
    }
    &__btn {
      background-color: transparent !important;
      border: 1px solid $color-grayLight;
      border-radius: 5%;
      color: $color-graySubDark !important;
      margin-left: 20px;
      &--selected {
        border-radius: 5%;
        border: 0;
        margin-left: 20px;
        background-color: $color-primary-light !important;
      }
    }
  }
}
.builder-view {
  &__cover-img {
    width: 200px;
  }
  &__question {
    color: $color-graySubDark;
    font-size: 0.8em;
  }
}

.track-view {
  &__drag-drop__left {
    @include flexbox(row, nowrap, flex-start);
    @include flex-align(center);
  }
  &__builder-title {
    width: 200px;
    margin: 0 10px !important;
  }
  &__cover-img {
    width: 50px;
  }
}

.manageSections {
  padding: 0;
  width: 95vw;
  min-height: 80vh;
  &__results {
    &__title {
      color: $color-graySubDark;
      font-size: 1.2em;
    }
    &__item {
      @include flexbox-sb;
      border-bottom: 1px solid $color-grayLight;
      padding: 10px 0;
      color: $color-graySubDark;
    }
    &__remove {
      color: $color-bright;
      &:before {
        content: '';
        background: url('/images/icon_check.png') no-repeat center left;
        padding: 10px 20px;
      }
    }
  }
}

.admin__modal {
  &__images {
    max-width: 90vw;
    max-height: 70vh;
    display: block;
  }
}
