@import '../../css/utilities/_mixin.scss';
@import '../../css/utilities/_variables.scss';

.popUp__timeStamp {
  color: $color-grayDark;
  margin: 0 0 5px 0;
  font-style: italic;
}

.popUp__note {
  margin: 2px 0;
  padding-top: 10px;
  color: $color-primary;
  width: 100%;
  font-size: 1.2em;
}
