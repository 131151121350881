//======================================
// VARIABLES
//======================================
//colors
$color-primary: #2274A5; //Rebel Blue
$color-primary-light: lighten($color-primary, 10%);
$color-primary-dark: darken($color-primary, 10%);

$color-secondary:#253435; //Greenish Black
$color-secondary-light: lighten($color-secondary, 15%);
$color-secondary-dark: darken($color-secondary, 5%);

$color-tertiary:#587792; //Smalt Blue
$color-tertiary-light: lighten($color-tertiary, 20%);
$color-tertiary-dark: darken($color-tertiary, 10%);

$color-accent: #19A4B1; //Meadow Teal
$color-accent-light: lighten($color-accent, 8%);
$color-accent-dark: darken($color-accent, 8%);

$color-pop: #F18F01; //Muted Orange
$color-pop-light: lighten($color-pop, 10%);
$color-pop-dark: darken($color-pop, 3%);

$color-bright: #ef6464; //red
$color-bright-light: lighten($color-bright,4%);
$color-bright-dark: darken($color-bright, 10%);

$white: #fff;
$color-bg: #f0f1f5; // almost white gray
$color-grayLight: #e8e9e9; //light gray
$color-grayDark: #b3b2b3; //dark gray
$color-graySubDark: #525454; //dark gray for reading

//states //ARCHIVED
$color-hover: lighten($color-primary, 10%);
$color-active: darken($color-primary, 10%);

//fonts
$font-h: 'Jura' , sans-serif;
$font-p: 'Muli', sans-serif;

//font weight + styles
$font-h-light: 300;
$font-h-normal: 500;
$font-h-bold: 700;

$font-p-normal: 400;
$font-p-italic: 400i;
$font-p-bold: 700;
$font-p-bolditalic: 700i;

//Layout
$xs-max-width: 90%;
$s-max-width: 90%;
$m-max-width: 90%;
$l-max-width: 850px;
$xl-width: 1080px;
$max-width: 1380px;

//Breakpoints
//$break-xs: 575px;
$break-xs: 300px;
$break-s: 475px;
$break-m: 618px;
$break-l: 1050px;
$break-xl: 1250px;
$break-xxl: 1550px;


//full-height

$full-height: calc(100vh - 38px);
