@import '../../../css/utilities/_mixin.scss';
@import '../../../css/utilities/_variables.scss';

.loading {
  margin: 0;
  height: 100%;
  overflow: hidden;
  @include flex;
  @include flex-justify(center);
  @include flex-align(top);
  padding-top: 50vh;
  background: $color-secondary;
  color: #ccc;
  min-height: 100vh;
  &__wrapper {
    position: fixed;
    z-index: 999;
    opacity: 0.99;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100%;
  }
  em {
    font-family: $font-h;
    color: currentColor;
    position: relative;
    font-style: normal;
    text-align: center;
  }
  h2 {
    margin: 0;
    height: 12vmin;
    width: 120vmin;
    @include flex;
    @include flex-justify(center);
    position: relative;
  }
  em.planet {
    -webkit-animation: planet-rotate 4s linear infinite;
    animation: planet-rotate 4s linear infinite;
    position: relative;
  }

  em.planet.left {
    -webkit-transform-origin: 40vmin 5vmin;
    transform-origin: 50vmin 5vmin;
  }

  @-webkit-keyframes planet-rotate {
    to {
      -webkit-transform: rotate(1turn);
    }
  }

  @keyframes planet-rotate {
    to {
      transform: rotate(1turn);
    }
  }
}

.loading::before,
.loading::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 0.5vmin;
  height: 0.5vmin;
  @include border-radius(50%);
  color: transparent;
}

.loading:before {
  -webkit-box-shadow: 93vw 63vh 0.3vmin rgba(255, 255, 255, 0.8),
    3vw 17vh 0.3vmin rgba(255, 255, 255, 0.8),
    28vw 85vh 0.3vmin rgba(255, 255, 255, 0.8),
    97vw 82vh 0.3vmin rgba(255, 255, 255, 0.8),
    55vw 83vh 0.3vmin rgba(255, 255, 255, 0.8),
    74vw 72vh 0.3vmin rgba(255, 255, 255, 0.8),
    84vw 11vh 0.3vmin rgba(255, 255, 255, 0.8),
    32vw 6vh 0.3vmin rgba(255, 255, 255, 0.8),
    45vw 92vh 0.3vmin rgba(255, 255, 255, 0.8),
    98vw 57vh 0.3vmin rgba(255, 255, 255, 0.8),
    63vw 98vh 0.3vmin rgba(255, 255, 255, 0.8),
    90vw 46vh 0.3vmin rgba(255, 255, 255, 0.8),
    50vw 86vh 0.3vmin rgba(255, 255, 255, 0.8),
    38vw 21vh 0.3vmin rgba(255, 255, 255, 0.8),
    74vw 2vh 0.3vmin rgba(255, 255, 255, 0.8),
    89vw 22vh 0.3vmin rgba(255, 255, 255, 0.8),
    39vw 0vh 0.3vmin rgba(255, 255, 255, 0.8),
    25vw 89vh 0.3vmin rgba(255, 255, 255, 0.8),
    54vw 58vh 0.3vmin rgba(255, 255, 255, 0.8),
    81vw 39vh 0.3vmin rgba(255, 255, 255, 0.8),
    51vw 8vh 0.3vmin rgba(255, 255, 255, 0.8),
    24vw 56vh 0.3vmin rgba(255, 255, 255, 0.8),
    50vw 23vh 0.3vmin rgba(255, 255, 255, 0.8),
    62vw 34vh 0.3vmin rgba(255, 255, 255, 0.8),
    10vw 77vh 0.3vmin rgba(255, 255, 255, 0.8),
    92vw 45vh 0.3vmin rgba(255, 255, 255, 0.8),
    70vw 40vh 0.3vmin rgba(255, 255, 255, 0.8),
    2vw 53vh 0.3vmin rgba(255, 255, 255, 0.8),
    3vw 54vh 0.3vmin rgba(255, 255, 255, 0.8),
    18vw 21vh 0.3vmin rgba(255, 255, 255, 0.8),
    48vw 47vh 0.3vmin rgba(255, 255, 255, 0.8),
    83vw 96vh 0.3vmin rgba(255, 255, 255, 0.8),
    26vw 32vh 0.3vmin rgba(255, 255, 255, 0.8),
    46vw 9vh 0.3vmin rgba(255, 255, 255, 0.8),
    2vw 13vh 0.3vmin rgba(255, 255, 255, 0.8),
    29vw 63vh 0.3vmin rgba(255, 255, 255, 0.8),
    17vw 90vh 0.3vmin rgba(255, 255, 255, 0.8),
    78vw 9vh 0.3vmin rgba(255, 255, 255, 0.8),
    15vw 39vh 0.3vmin rgba(255, 255, 255, 0.8),
    90vw 5vh 0.3vmin rgba(255, 255, 255, 0.8),
    30vw 90vh 0.2vmin rgba(190, 200, 255, 0.9);
  -moz-box-shadow: 93vw 63vh 0.3vmin rgba(255, 255, 255, 0.8),
    3vw 17vh 0.3vmin rgba(255, 255, 255, 0.8),
    28vw 85vh 0.3vmin rgba(255, 255, 255, 0.8),
    97vw 82vh 0.3vmin rgba(255, 255, 255, 0.8),
    55vw 83vh 0.3vmin rgba(255, 255, 255, 0.8),
    74vw 72vh 0.3vmin rgba(255, 255, 255, 0.8),
    84vw 11vh 0.3vmin rgba(255, 255, 255, 0.8),
    32vw 6vh 0.3vmin rgba(255, 255, 255, 0.8),
    45vw 92vh 0.3vmin rgba(255, 255, 255, 0.8),
    98vw 57vh 0.3vmin rgba(255, 255, 255, 0.8),
    63vw 98vh 0.3vmin rgba(255, 255, 255, 0.8),
    90vw 46vh 0.3vmin rgba(255, 255, 255, 0.8),
    50vw 86vh 0.3vmin rgba(255, 255, 255, 0.8),
    38vw 21vh 0.3vmin rgba(255, 255, 255, 0.8),
    74vw 2vh 0.3vmin rgba(255, 255, 255, 0.8),
    89vw 22vh 0.3vmin rgba(255, 255, 255, 0.8),
    39vw 0vh 0.3vmin rgba(255, 255, 255, 0.8),
    25vw 89vh 0.3vmin rgba(255, 255, 255, 0.8),
    54vw 58vh 0.3vmin rgba(255, 255, 255, 0.8),
    81vw 39vh 0.3vmin rgba(255, 255, 255, 0.8),
    51vw 8vh 0.3vmin rgba(255, 255, 255, 0.8),
    24vw 56vh 0.3vmin rgba(255, 255, 255, 0.8),
    50vw 23vh 0.3vmin rgba(255, 255, 255, 0.8),
    62vw 34vh 0.3vmin rgba(255, 255, 255, 0.8),
    10vw 77vh 0.3vmin rgba(255, 255, 255, 0.8),
    92vw 45vh 0.3vmin rgba(255, 255, 255, 0.8),
    70vw 40vh 0.3vmin rgba(255, 255, 255, 0.8),
    2vw 53vh 0.3vmin rgba(255, 255, 255, 0.8),
    3vw 54vh 0.3vmin rgba(255, 255, 255, 0.8),
    18vw 21vh 0.3vmin rgba(255, 255, 255, 0.8),
    48vw 47vh 0.3vmin rgba(255, 255, 255, 0.8),
    83vw 96vh 0.3vmin rgba(255, 255, 255, 0.8),
    26vw 32vh 0.3vmin rgba(255, 255, 255, 0.8),
    46vw 9vh 0.3vmin rgba(255, 255, 255, 0.8),
    2vw 13vh 0.3vmin rgba(255, 255, 255, 0.8),
    29vw 63vh 0.3vmin rgba(255, 255, 255, 0.8),
    17vw 90vh 0.3vmin rgba(255, 255, 255, 0.8),
    78vw 9vh 0.3vmin rgba(255, 255, 255, 0.8),
    15vw 39vh 0.3vmin rgba(255, 255, 255, 0.8),
    90vw 5vh 0.3vmin rgba(255, 255, 255, 0.8),
    30vw 90vh 0.2vmin rgba(190, 200, 255, 0.9);
  box-shadow: 93vw 63vh 0.3vmin rgba(255, 255, 255, 0.8),
    3vw 17vh 0.3vmin rgba(255, 255, 255, 0.8),
    28vw 85vh 0.3vmin rgba(255, 255, 255, 0.8),
    97vw 82vh 0.3vmin rgba(255, 255, 255, 0.8),
    55vw 83vh 0.3vmin rgba(255, 255, 255, 0.8),
    74vw 72vh 0.3vmin rgba(255, 255, 255, 0.8),
    84vw 11vh 0.3vmin rgba(255, 255, 255, 0.8),
    32vw 6vh 0.3vmin rgba(255, 255, 255, 0.8),
    45vw 92vh 0.3vmin rgba(255, 255, 255, 0.8),
    98vw 57vh 0.3vmin rgba(255, 255, 255, 0.8),
    63vw 98vh 0.3vmin rgba(255, 255, 255, 0.8),
    90vw 46vh 0.3vmin rgba(255, 255, 255, 0.8),
    50vw 86vh 0.3vmin rgba(255, 255, 255, 0.8),
    38vw 21vh 0.3vmin rgba(255, 255, 255, 0.8),
    74vw 2vh 0.3vmin rgba(255, 255, 255, 0.8),
    89vw 22vh 0.3vmin rgba(255, 255, 255, 0.8),
    39vw 0vh 0.3vmin rgba(255, 255, 255, 0.8),
    25vw 89vh 0.3vmin rgba(255, 255, 255, 0.8),
    54vw 58vh 0.3vmin rgba(255, 255, 255, 0.8),
    81vw 39vh 0.3vmin rgba(255, 255, 255, 0.8),
    51vw 8vh 0.3vmin rgba(255, 255, 255, 0.8),
    24vw 56vh 0.3vmin rgba(255, 255, 255, 0.8),
    50vw 23vh 0.3vmin rgba(255, 255, 255, 0.8),
    62vw 34vh 0.3vmin rgba(255, 255, 255, 0.8),
    10vw 77vh 0.3vmin rgba(255, 255, 255, 0.8),
    92vw 45vh 0.3vmin rgba(255, 255, 255, 0.8),
    70vw 40vh 0.3vmin rgba(255, 255, 255, 0.8),
    2vw 53vh 0.3vmin rgba(255, 255, 255, 0.8),
    3vw 54vh 0.3vmin rgba(255, 255, 255, 0.8),
    18vw 21vh 0.3vmin rgba(255, 255, 255, 0.8),
    48vw 47vh 0.3vmin rgba(255, 255, 255, 0.8),
    83vw 96vh 0.3vmin rgba(255, 255, 255, 0.8),
    26vw 32vh 0.3vmin rgba(255, 255, 255, 0.8),
    46vw 9vh 0.3vmin rgba(255, 255, 255, 0.8),
    2vw 13vh 0.3vmin rgba(255, 255, 255, 0.8),
    29vw 63vh 0.3vmin rgba(255, 255, 255, 0.8),
    17vw 90vh 0.3vmin rgba(255, 255, 255, 0.8),
    78vw 9vh 0.3vmin rgba(255, 255, 255, 0.8),
    15vw 39vh 0.3vmin rgba(255, 255, 255, 0.8),
    90vw 5vh 0.3vmin rgba(255, 255, 255, 0.8),
    30vw 90vh 0.2vmin rgba(190, 200, 255, 0.9);
}

.loading:after {
  -webkit-box-shadow: 67vw 35vh 0.2vmin 0.1vmin rgba(255, 200, 200, 0.8),
    89vw 13vh 0.2vmin 0.1vmin rgba(255, 200, 200, 0.8),
    77vw 62vh 0.2vmin 0.1vmin rgba(255, 200, 200, 0.8),
    1vw 74vh 0.2vmin 0.1vmin rgba(255, 200, 200, 0.8),
    38vw 15vh 0.2vmin 0.1vmin rgba(255, 200, 200, 0.8),
    79vw 10vh 0.2vmin 0.1vmin rgba(255, 200, 200, 0.8),
    16vw 47vh 0.2vmin 0.1vmin rgba(255, 200, 200, 0.8),
    74vw 71vh 0.2vmin 0.1vmin rgba(255, 200, 200, 0.8),
    30vw 96vh 0.2vmin 0.1vmin rgba(255, 200, 200, 0.8),
    13vw 98vh 0.2vmin 0.1vmin rgba(255, 200, 200, 0.8),
    9vw 40vh 0.2vmin 0.1vmin rgba(255, 200, 200, 0.8),
    9vw 71vh 0.2vmin 0.1vmin rgba(255, 200, 200, 0.8),
    12vw 93vh 0.2vmin 0.1vmin rgba(255, 200, 200, 0.8),
    91vw 26vh 0.2vmin 0.1vmin rgba(255, 200, 200, 0.8),
    73vw 48vh 0.2vmin 0.1vmin rgba(255, 200, 200, 0.8),
    61vw 46vh 0.2vmin 0.1vmin rgba(255, 200, 200, 0.8),
    20vw 3vh 0.2vmin 0.1vmin rgba(255, 200, 200, 0.8),
    90vw 59vh 0.2vmin 0.1vmin rgba(255, 200, 200, 0.8),
    48vw 72vh 0.2vmin 0.1vmin rgba(255, 200, 200, 0.8),
    91vw 68vh 0.2vmin 0.1vmin rgba(255, 200, 200, 0.8);
  -moz-box-shadow: 67vw 35vh 0.2vmin 0.1vmin rgba(255, 200, 200, 0.8),
    89vw 13vh 0.2vmin 0.1vmin rgba(255, 200, 200, 0.8),
    77vw 62vh 0.2vmin 0.1vmin rgba(255, 200, 200, 0.8),
    1vw 74vh 0.2vmin 0.1vmin rgba(255, 200, 200, 0.8),
    38vw 15vh 0.2vmin 0.1vmin rgba(255, 200, 200, 0.8),
    79vw 10vh 0.2vmin 0.1vmin rgba(255, 200, 200, 0.8),
    16vw 47vh 0.2vmin 0.1vmin rgba(255, 200, 200, 0.8),
    74vw 71vh 0.2vmin 0.1vmin rgba(255, 200, 200, 0.8),
    30vw 96vh 0.2vmin 0.1vmin rgba(255, 200, 200, 0.8),
    13vw 98vh 0.2vmin 0.1vmin rgba(255, 200, 200, 0.8),
    9vw 40vh 0.2vmin 0.1vmin rgba(255, 200, 200, 0.8),
    9vw 71vh 0.2vmin 0.1vmin rgba(255, 200, 200, 0.8),
    12vw 93vh 0.2vmin 0.1vmin rgba(255, 200, 200, 0.8),
    91vw 26vh 0.2vmin 0.1vmin rgba(255, 200, 200, 0.8),
    73vw 48vh 0.2vmin 0.1vmin rgba(255, 200, 200, 0.8),
    61vw 46vh 0.2vmin 0.1vmin rgba(255, 200, 200, 0.8),
    20vw 3vh 0.2vmin 0.1vmin rgba(255, 200, 200, 0.8),
    90vw 59vh 0.2vmin 0.1vmin rgba(255, 200, 200, 0.8),
    48vw 72vh 0.2vmin 0.1vmin rgba(255, 200, 200, 0.8),
    91vw 68vh 0.2vmin 0.1vmin rgba(255, 200, 200, 0.8);
  box-shadow: 67vw 35vh 0.2vmin 0.1vmin rgba(255, 200, 200, 0.8),
    89vw 13vh 0.2vmin 0.1vmin rgba(255, 200, 200, 0.8),
    77vw 62vh 0.2vmin 0.1vmin rgba(255, 200, 200, 0.8),
    1vw 74vh 0.2vmin 0.1vmin rgba(255, 200, 200, 0.8),
    38vw 15vh 0.2vmin 0.1vmin rgba(255, 200, 200, 0.8),
    79vw 10vh 0.2vmin 0.1vmin rgba(255, 200, 200, 0.8),
    16vw 47vh 0.2vmin 0.1vmin rgba(255, 200, 200, 0.8),
    74vw 71vh 0.2vmin 0.1vmin rgba(255, 200, 200, 0.8),
    30vw 96vh 0.2vmin 0.1vmin rgba(255, 200, 200, 0.8),
    13vw 98vh 0.2vmin 0.1vmin rgba(255, 200, 200, 0.8),
    9vw 40vh 0.2vmin 0.1vmin rgba(255, 200, 200, 0.8),
    9vw 71vh 0.2vmin 0.1vmin rgba(255, 200, 200, 0.8),
    12vw 93vh 0.2vmin 0.1vmin rgba(255, 200, 200, 0.8),
    91vw 26vh 0.2vmin 0.1vmin rgba(255, 200, 200, 0.8),
    73vw 48vh 0.2vmin 0.1vmin rgba(255, 200, 200, 0.8),
    61vw 46vh 0.2vmin 0.1vmin rgba(255, 200, 200, 0.8),
    20vw 3vh 0.2vmin 0.1vmin rgba(255, 200, 200, 0.8),
    90vw 59vh 0.2vmin 0.1vmin rgba(255, 200, 200, 0.8),
    48vw 72vh 0.2vmin 0.1vmin rgba(255, 200, 200, 0.8),
    91vw 68vh 0.2vmin 0.1vmin rgba(255, 200, 200, 0.8);
}
