.select-search {
  width: 100%;
  position: relative;
}

.select-search__value {
  position: relative;
  z-index: 1;
}

.select-search__value::after {
  content: '';
  display: inline-block;
  position: absolute;
  top: calc(50% - 9px);
  right: 19px;
  width: 11px;
  height: 11px;
}

.select-search__input {
  display: block;
  height: calc(1.5em + 0.75rem + 2px);
  width: 100%;
  color: #6e707e;
  padding: 6px 40px 6px 12px;
  background: #fff;
  border-radius: 0.35rem;
  outline: none;
  font-size: 1rem;
  text-align: left;
  text-overflow: ellipsis;
  line-height: 1.5;
  -webkit-appearance: none;
  border: 1px solid #d1d3e2;
}

.select-search__input::-webkit-search-decoration,
.select-search__input::-webkit-search-cancel-button,
.select-search__input::-webkit-search-results-button,
.select-search__input::-webkit-search-results-decoration {
  -webkit-appearance: none;
}

.select-search__input:not([readonly]):focus {
  cursor: initial;
  box-shadow: 0 0 0 0.2rem rgb(78 115 223 / 25%);
}

.select-search__select {
  background: #fff;
}

.select-search__options {
  list-style: none;
  padding-left: 0;
}

.select-search__row:not(:first-child) {
  border-top: 1px solid #d1d3e2;
}

.select-search__option,
.select-search__not-found {
  display: block;
  min-height: 36px;
  height: max-content;
  width: 100%;
  padding: 0 16px;
  background: #fff;
  border: none;
  outline: none;
  font-size: 1rem;
  text-align: left;
  cursor: pointer;
}

.select-search__option.is-selected {
  background: #4e73df;
 color: #fff;
}

.select-search__option.is-highlighted,
.select-search__option:not(.is-selected):hover {
  background: #4e73df50;
}

.select-search:not(.is-loading):not(.select-search--multiple)
  .select-search__value::after {
  transform: rotate(45deg);
  border-right: 1px solid #000;
  border-bottom: 1px solid #000;
  pointer-events: none;
}

.select-search:not(.select-search--multiple) .select-search__select {
  position: absolute;
  z-index: 2;
  top: 44px;
  right: 0;
  left: 0;
  border-radius: 3px;
  overflow: auto;
  max-height: 360px;
}

.select-search__not-found {
  height: auto;
  padding: 16px;
  text-align: center;
  color: #888;
}
