//======================================
// MIXINS
//======================================
@mixin headerfont($family, $size, $weight) {
  font-family: $family;
  font-size: $size;
  font-weight: $weight;
}

//======================================
// LAYOUT
//======================================
@mixin flex {
  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */
  display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */
  display: -ms-flexbox; /* TWEENER - IE 10 */
  display: -webkit-flex; /* NEW - Chrome */
  display: flex;
}

@mixin flex-justify($justify) {
  -moz-justify-content: $justify; /* Firefox 18.0 */
  -webkit-justify-content: $justify; /* Safari 6.1+ */
  justify-content: $justify;
}

@mixin flex-direction($direction) {
  -moz-flex-direction: $direction;
  -webkit-flex-direction: $direction;
  flex-direction: $direction;
}

@mixin flex-wrap($wrap) {
  -moz-flex-wrap: $wrap;
  -webkit-flex-wrap: $wrap;
  flex-wrap: $wrap;
}

@mixin flexbox($direction, $wrap, $justify) {
  @include flex;
  @include flex-direction($direction);
  @include flex-wrap($wrap);
  @include flex-justify($justify);
}

@mixin flexbox-sb {
  @include flexbox(row, nowrap, space-between);
}

@mixin flex-order($order-number) {
  -webkit-box-ordinal-group: $order-number; /* OLD - iOS 6-, Safari 3.1-6 */
  -moz-box-ordinal-group: $order-number; /* OLD - Firefox 19- */
  -ms-flex-order: $order-number; /* TWEENER - IE 10 */
  -webkit-order: $order-number; /* NEW - Chrome */
  order: $order-number; /* NEW, Spec - Opera 12.1, Firefox 20+ */
}

@mixin flex-align($align) {
  -webkit-box-align: $align;
  -moz-box-align: $align;
  -ms-flex-align: $align;
  -webkit-align-items: $align;
  align-items: $align;
}

@mixin display-grid {
  display: -ms-grid;
  display: grid;
}

@mixin margin-auto {
  margin-right: auto;
  margin-left: auto;
}

@mixin vertical-hor-align {
  @include flex;
  @include flex-direction(column);
  @include flex-align(center);
  @include flex-justify(center);
  margin: auto 0;
  min-height: $full-height;
}

@mixin right-align-block {
  margin-right: 0;
  margin-left: auto;
  display: block;
  width: fit-content;
}

@mixin center-align-block {
  margin-right: auto;
  margin-left: auto;
  display: block;
  width: fit-content;
}

@mixin modal-wrap($min-width) {
  max-width: $min-width;
  @media (max-width: $break-l) {
    max-width: 90vw;
  }
}

@mixin send-btn {
  width: 100px;
  padding: 10px 5px 10px 15px;
  color: $color-primary-light;
  background: url(/images/send-mail__icon.png) no-repeat left center;
  display: block;
  border: 1px solid $color-accent;
  color: $color-accent;
  cursor: pointer;
  &:hover,
  &.hover {
    color: $color-secondary;
    font-weight: $font-h-bold;
    background: $color-accent
      url(../../public/images/send-mail__icon__white.png) no-repeat right center;
  }
  &:active &.active {
    background: $color-accent-dark
      url(../../public/images/send-mail__icon__white.png) no-repeat right center;
    color: $white;
  }
}

@mixin inner__layout--content {
  max-width: $xs-max-width;
  width: $xs-max-width;
  margin-left: auto;
  margin-right: auto;
  @media (min-width: $break-s) {
    max-width: $s-max-width;
    width: $s-max-width;
    margin-left: auto;
    margin-right: auto;
  }
  @media (min-width: $break-m) {
    max-width: $m-max-width;
    width: $m-max-width;
    margin-left: auto;
    margin-right: auto;
  }
  @media (min-width: $break-l) {
    max-width: $l-max-width;
    width: $l-max-width;
    margin-left: auto;
    margin-right: auto;
  }
  @media (min-width: $break-xl) {
    max-width: $xl-width;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }
  @media (min-width: $break-xxl) {
    max-width: $max-width;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }
}

@mixin inner__layout--whole {
  max-width: $xs-max-width;
  display: block;
  margin: 0 auto;
  padding: 15px 0;
  @media (min-width: $break-s) {
    max-width: $s-max-width;
    margin: 0 auto;
    padding: 15px 0;
  }
  @media (min-width: $break-m) {
    max-width: $m-max-width;
    margin: 0 auto;
    padding: 20px 0;
  }
  @media (min-width: $break-l) {
    max-width: $l-max-width;
    margin: 0 auto;
    padding: 20px 0;
  }
  @media (min-width: $break-xl) {
    max-width: $xl-width;
    margin: 0 auto;
    padding: 30px 0;
  }
  @media (min-width: $break-xxl) {
    max-width: $max-width;
    margin: 0 auto;
    padding: 30px 0;
  }
}

@mixin hub__inner-wrap {
  margin: 0 auto;
  width: 90%;
  @media (max-width: $break-m) {
    margin-bottom: 3em;
    margin: 0 5%;
  }
  @media (min-width: $break-xl) {
    max-width: $xl-width;
    margin: 0 auto;
    padding: 30px 0 0;
  }
  @media (min-width: $break-xxl) {
    max-width: $max-width;
    margin: 0 auto;
    padding: 30px 0 0;
  }
}

@mixin scrollbars(
  $size,
  $foreground-color,
  $background-color: mix($foreground-color, white, 50%)
) {
  // For Google Chrome
  &::-webkit-scrollbar {
    width: $size;
    height: $size;
  }
  &::-webkit-scrollbar-thumb {
    background: $foreground-color;
  }
  &::-webkit-scrollbar-track {
    background: $background-color;
  }
  // For Internet Explorer
  body {
    scrollbar-face-color: $foreground-color;
    scrollbar-track-color: $background-color;
  }
}
@mixin scrollbars-general {
  @include scrollbars(0.5em, $color-grayDark, darken($color-grayLight, 3%));
}

@mixin hexagon($height, $color) {
  height: $height;
  width: calc(#{$height}* 0.666666);
  background: $color;
  position: relative;
  &:before,
  &:after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
  }
  &:before {
    left: calc(#{$height}/ -4);
    top: 0;
    border-top: calc(#{$height}/ 2) solid transparent;
    border-bottom: calc(#{$height}/ 2) solid transparent;
    border-right: calc(#{$height}/ 4) solid $color;
  }
  &:after {
    right: calc(#{$height}/ -4);
    bottom: 0;
    border-top: calc(#{$height}/ 2) solid transparent;
    border-bottom: calc(#{$height}/ 2) solid transparent;
    border-left: calc(#{$height}/ 3.5) solid $color;
  }
}

//NUMBER OF CHILDREN
@mixin has-nth($expression, $element: '*') {
  &:nth-last-child(#{$expression}):first-child,
  &:nth-last-child(#{$expression}):first-child ~ #{$element} {
    @content;
  }
}

@mixin sticky {
  @include css3-prefix-v(position, sticky);
}

//======================================
// Shared
//======================================

//======================================
// BROWSER SUPPORT
//======================================

/// Adds a browser prefix to the property
@mixin css3-prefix($property, $value) {
  -webkit-#{$property}: #{$value};
  -khtml-#{$property}: #{$value};
  -moz-#{$property}: #{$value};
  -ms-#{$property}: #{$value};
  -o-#{$property}: #{$value};
  #{$property}: #{$value};
}

/// Adds a browser prefix to the value
@mixin css3-prefix-v($property, $value) {
  #{$property}: -webkit-#{$value};
  #{$property}: -khtml-#{$value};
  #{$property}: -moz-#{$value};
  #{$property}: -ms-#{$value};
  #{$property}: -o-#{$value};
  #{$property}: #{$value};
}

@mixin word-wrap {
  @include css3-prefix('overflow-wrap', break-word);
  //@include css3-prefix('hyphens', auto);
}

@mixin border-box {
  @include css3-prefix('box-sizing', border-box);
}

@mixin box-shadow($top, $right, $bottom, $left, $red, $green, $blue, $alpha) {
  @include css3-prefix(
    'box-shadow',
    $top $right $bottom $left rgba($red, $green, $blue, $alpha)
  );
}

/// Background Gradient
@mixin background-gradient(
  $startColor,
  $startPercentage,
  $endColor,
  $endPercentage
) {
  background-color: $startColor; /* Old browsers */
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from($startColor),
    to($endColor)
  );
  background-image: -webkit-linear-gradient(
    top,
    $startColor $startPercentage,
    $endColor $endPercentage
  ); /* Chrome10-25,Safari5.1-6 */
  background-image: -moz-linear-gradient(
    top,
    $startColor $startPercentage,
    $endColor $endPercentage
  ); /* FF3.6-15 */
  background-image: -ms-linear-gradient(
    top,
    $startColor $startPercentage,
    $endColor $endPercentage
  );
  background-image: -o-linear-gradient(
    top,
    $startColor $startPercentage,
    $endColor $endPercentage
  );
  background-image: linear-gradient(
    top,
    $startColor $startPercentage,
    $endColor $endPercentage
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorStr= $startColor, endColorStr= $endColor);
}

@mixin border-radius($radius) {
  @include css3-prefix('border-radius', $radius);
}

//Builder Only
@mixin builder-inner-content {
  display: block;
  margin-left: auto;
  margin-right: auto;
  padding-left: 5%;
  padding-right: 5%;
  max-width: 1580px;
}
