@import '../../css/utilities/_mixin.scss';
@import '../../css/utilities/_variables.scss';

.header {
  background-color: $white;
  margin: 0;
  position: relative;
  z-index: 2;
  grid-area: header;
  height: 35px;
  box-sizing: border-box;
  width: 100%;
}

.adminTop {
  @include border-box;
  height: 35px;
  @include flexbox-sb;
  @include flex-align(center);
  background-color: $white;
  position: fixed;
  border-bottom: 1.5px solid $color-grayLight;
  top: 0;
  a {
    font-size: 0.8em;
  }
  &--sideOpen {
    @extend .adminTop;
    width: calc(100vw - 140px);
  }
  li {
    display: inline-block;
    padding: 8px 5px;
    margin: 1px;
    &:hover {
      background-color: $color-bg;
    }
    a {
      color: $color-primary;
      p {
        display: inline-block;
        padding: 0px;
        margin: 0px;
        white-space: nowrap;
      }
    }
  }
  &__icon {
    width: auto;
    height: 15px;
    margin-right: 5px;
  }
  &__label {
    @media (max-width: $break-l) {
      display: none !important;
    }
  }
  &__settingsL,
  .inApp__helpL,
  .inApp__notifications {
    @media (max-width: $break-m) {
      display: none !important;
    }
  }
  &__drpdwn {
    position: relative;
    display: inline-block;
    margin: 5px 0;
    &:hover .inApp__drpdwnContent {
      display: block;
    }
    img {
      width: 15px;
      height: auto;
    }
  }
  &__drpdwnContent {
    display: none;
    position: fixed;
    background-color: $white;
    min-width: 250px;
    @include box-shadow(0px, 8px, 16px, 0px, 0, 0, 0, 0.2);
    z-index: 4;
    text-align: center;
    border: 0.5px solid $color-grayLight;
    min-width: 125px !important;
    right: -2px;
    p {
      margin: 5px 0 0 0;
      padding: 0 auto;
    }
    &__links {
      color: $color-secondary-light;
      @include flexbox(row, nowrap, center);
      color: $color-grayDark;
      padding: 12px 16px;
      text-decoration: none;
      &:hover {
        border: 0.5px solid $color-secondary-light;
      }
      @media (max-width: $break-s) {
        padding: 20px 16px;
      }
      &--nav {
        @media (min-width: $break-m) {
          display: none !important;
        }
        @media (max-width: $break-s) {
          font-size: 1em;
          p {
            display: block;
          }
        }
      }
    }
    &--logout {
      border: 1px solid $color-grayLight;
      background-color: $color-bg;
      text-align: center;
      p {
        width: 100%;
      }
    }
  }
}
